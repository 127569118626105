import axios from 'axios'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import Form from 'react-jsonschema-form'
import styled from 'styled-components'
import useAxiosGet from '../../components/useAxiosGet.js'
import CheckmarkIcon from '../../images/checkmark-path.svg'
import EnvelopeIcon from '../../images/envelope.svg'

const ContainerMedium = styled.div`
  max-width: 750px;
  margin-left: auto;
  margin-right: auto;

  .headline--intro {
    margin-bottom: 0;
  }

  form {
    fieldset,
    legend {
      margin-bottom: 0;
    }

    #root,
    #root_ccCustomer,
    .field-object {
      margin: 0;
    }

    input,
    select,
    textarea {
      width: 100%;
    }

    input {
      &[type="checkbox"] {
        width: auto;
      }
    }

    .field {
      margin-top: 10px;
    }

    > .field:first-child {
      margin-top: 0;
    }


    .has-error {
      input,
      select,
      textarea {
        border-color: ${props => props.theme.colors.errorRed};
      }

      .text-danger {
        margin: 5px 0 0;
        line-height: 1.3;
        color: ${props => props.theme.colors.errorRed};
      }
    }

    .cc-customer-field,
    .attachment-input {
      cursor: pointer;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }

    .attachment-input {
      > label {
        &::before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 20px;
          margin-right: 12px;
          background: transparent url('data:image/svg+xml;utf8,<svg viewBox="0 0 10 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><path d="M8.63636364,4.54545455 L8.63636364,15 C8.63636364,17.0090909 7.00909091,18.6363636 5,18.6363636 C2.99090909,18.6363636 1.36363636,17.0090909 1.36363636,15 L1.36363636,3.63636364 C1.36363636,2.38181818 2.38181818,1.36363636 3.63636364,1.36363636 C4.89090909,1.36363636 5.90909091,2.38181818 5.90909091,3.63636364 L5.90909091,13.1818182 C5.90909091,13.6818182 5.5,14.0909091 5,14.0909091 C4.5,14.0909091 4.09090909,13.6818182 4.09090909,13.1818182 L4.09090909,4.54545455 L2.72727273,4.54545455 L2.72727273,13.1818182 C2.72727273,14.4363636 3.74545455,15.4545455 5,15.4545455 C6.25454545,15.4545455 7.27272727,14.4363636 7.27272727,13.1818182 L7.27272727,3.63636364 C7.27272727,1.62727273 5.64545455,0 3.63636364,0 C1.62727273,0 0,1.62727273 0,3.63636364 L0,15 C0,17.7636364 2.23636364,20 5,20 C7.76363636,20 10,17.7636364 10,15 L10,4.54545455 L8.63636364,4.54545455 Z" fill="${props =>
            props.theme.colors.darkText.replace(
              '#',
              '%23'
            )}" fill-rule="nonzero"></path></g></svg>') center center no-repeat;
        }
      }

      > div {
        // Visually hidden
        position: absolute !important;
        clip: rect(1px, 1px, 1px, 1px);
        padding: 0 !important;
        border: 0 !important;
        height: 1px !important;
        width: 1px !important;
        overflow: hidden;
      }
    }

    .cc-customer-field {
      label {
        svg {
          margin-right: 8px;
        }
      }
    }

    .btn--submit {
      margin-top: 10px;
      width: 100%;

      @media screen and (min-width: 426px) {
        max-width: 230px;
      }

      span {
        width: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 120ms ease 60ms;

        svg {
          width: 14px;
          margin-bottom: -3px;
          margin-left: 5px;
        }

        &.show {
          width: auto;
          opacity: 1;
          visibility: visible;

          &.submitting {
            &::after {
              content: ".";
              display: inline-block;
              animation: dots 1500ms steps(5, end) infinite;
            }

            @keyframes dots {
              0%,
              20% {
                color: rgba(0, 0, 0, 0);
                text-shadow: 4px 0 0 rgba(0, 0, 0, 0), 8px 0 0 rgba(0, 0, 0, 0);
              }
              40% {
                color: currentColor;
                text-shadow: 4px 0 0 rgba(0, 0, 0, 0), 8px 0 0 rgba(0, 0, 0, 0);
              }
              60% {
                text-shadow: 4px 0 0 currentColor, 8px 0 0 rgba(0, 0, 0, 0);
              }
              80%,
              100% {
                text-shadow: 4px 0 0 currentColor, 8px 0 0 currentColor;
              }
            }
          }

          &.submitted {
            svg {
              .path {
                stroke-dasharray: 30;
                stroke-dashoffset: 30;
                animation: dash 800ms ease forwards;
              }
            }

            @keyframes dash {
              to {
                stroke-dashoffset: 0;
              }
            }
          }
        }
      }
    }

    .attachment-input {
      margin-top: 3px;
    }

    .cc-customer-field {
      margin-top: 0;

      .field {
        margin-top: 0;
      }
    }

    .attachment-input,
    .cc-customer-field {
      label {
        padding-top: 8px;
        padding-bottom: 7px;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    form {
      .field {
        margin-top: 15px;
      }

      .attachment-input {
        margin-top: 4px;
      }

      .field-object {
        margin-top: 0;
      }
    }
  }

  @media screen and (min-width: ${props => props.theme.responsive.large}) {
    form {
      .btn--submit {
        margin-top: 25px;
      }
    }
  }
`

const { GATSBY_FORM_SERVICE_BASE_URL } = process.env

const Contact = () => {
  const { response, loading, error } = useAxiosGet({
    url: `${GATSBY_FORM_SERVICE_BASE_URL}/support/tickets/fields`,
  })

  const { data: [{ choices: reasonChoices = [] } = {}] = [] } = response || {}

  const NumericWidget = props => {
    return (
      <input
        type="number"
        id={props.id}
        value={props.value}
        pattern="[0-9]*"
        inputMode="numeric"
        placeholder={props.placeholder}
        required={props.required}
        onChange={event => props.onChange(event.target.value)}
      />
    )
  }

  const AddCCWidget = props => {
    return (
      <label className="cc-customer" htmlFor="ccCustomer">
        <input
          type="checkbox"
          id="ccCustomer"
          value={props.checked}
          required={props.required}
          onChange={event => props.onChange(event.target.checked)}
        />
        <EnvelopeIcon width="17px" />
        <span>CC your customer</span>
      </label>
    )
  }

  const schema = {
    type: 'object',
    properties: {
      userInfo: {
        title: 'Tell us who you are',
        type: 'object',
        required: ['fullName', 'email', 'artistID'],
        properties: {
          fullName: { type: 'string', title: 'Full Name' },
          email: { type: 'string', title: 'Email Address' },
          artistID: { type: 'number', title: 'Artist ID', pattern: '^\\d*$' },
        },
      },
      userReason: {
        title: 'What do you need help with?',
        type: 'object',
        required: ['reason'],
        properties: {
          reason: {
            type: 'string',
            title: 'Select the reason for contacting',
            enum: reasonChoices,
            enumNames: reasonChoices,
          },
        },
        dependencies: {
          reason: {
            oneOf: [
              {
                properties: {
                  reason: {
                    enum: reasonChoices.filter(
                      choice =>
                        !choice.toLowerCase().includes('order') &&
                        !choice.toLowerCase().includes('return')
                    ),
                  },
                },
              },
              {
                properties: {
                  reason: {
                    enum: reasonChoices.filter(choice =>
                      choice.toLowerCase().includes('order')
                    ),
                  },
                  orderNumber: {
                    title: 'Order Number',
                    type: 'number',
                    pattern: '^\\d*$',
                  },
                },
                required: ['orderNumber'],
              },
              {
                properties: {
                  reason: {
                    enum: reasonChoices.filter(choice =>
                      choice.toLowerCase().includes('return')
                    ),
                  },
                  orderNumber: {
                    title: 'Order Number',
                    type: 'number',
                    pattern: '^\\d*$',
                  },
                },
              },
            ],
          },
        },
      },
      userSubject: {
        type: 'string',
        title: 'Subject',
      },
      userMessage: {
        type: 'string',
        title: 'Message body',
      },
      attachments: {
        title: 'Attach an image',
        type: 'array',
        items: {
          type: 'string',
          format: 'data-url',
        },
      },
      ccCustomer: {
        title: '',
        type: 'object',
        properties: {
          showCCField: {
            type: 'boolean',
            title: 'CC your customer',
            enum: [true, false],
            default: false,
          },
        },
        dependencies: {
          showCCField: {
            oneOf: [
              {
                properties: {
                  showCCField: {
                    enum: [false],
                  },
                },
              },
              {
                properties: {
                  showCCField: {
                    enum: [true],
                  },
                  ccEmail: {
                    title: 'Customer Email',
                    type: 'string',
                  },
                },
              },
            ],
          },
        },
      },
    },
    required: ['userMessage', 'userSubject'],
  }

  const uiSchema = {
    userInfo: {
      fullName: {
        'ui:placeholder': 'Full Name',
        'ui:options': {
          label: false,
        },
      },
      email: {
        'ui:options': {
          inputType: 'email',
          label: false,
        },
        'ui:placeholder': 'Email',
      },
      artistID: {
        'ui:widget': NumericWidget,
        'ui:placeholder': 'Artist ID',
        'ui:options': {
          label: false,
        },
      },
    },
    userReason: {
      reason: {
        classNames: 'select-wrapper',
        'ui:widget': 'select',
        'ui:placeholder': 'Choose One',
        'ui:options': {
          label: false,
        },
      },
      orderNumber: {
        'ui:widget': NumericWidget,
        'ui:placeholder': 'Order Number',
        'ui:options': {
          label: false,
        },
      },
    },
    userSubject: {
      'ui:placeholder': 'Subject',
      'ui:options': {
        label: false,
      },
    },
    userMessage: {
      'ui:widget': 'textarea',
      'ui:placeholder': 'Tell us how we can help you…',
      'ui:options': {
        label: false,
      },
    },
    attachments: {
      classNames: 'attachment-input',
    },
    ccCustomer: {
      classNames: 'cc-customer-field',
      showCCField: {
        'ui:widget': AddCCWidget,
        'ui:options': {
          label: false,
        },
      },
      ccEmail: {
        'ui:options': {
          label: false,
          inputType: 'email',
        },
        'ui:placeholder': 'Customer Email',
      },
    },
  }

  const handleChange = (data, e) => {
    if (typeof document !== 'undefined') {
      const fileInputLabel =
        document && document.querySelector('.attachment-input > label')
      const attachedFiles =
        data.formData &&
        data.formData.attachments &&
        data.formData.attachments.length
      if (fileInputLabel && attachedFiles === 1) {
        fileInputLabel.innerHTML = '1 file attached'
      } else if (fileInputLabel && attachedFiles > 1) {
        fileInputLabel.innerHTML = `${attachedFiles} files attached`
      } else if (fileInputLabel) {
        fileInputLabel.innerHTML = 'Attach an image'
      }
    }
  }

  const log = type => console.log.bind(console, type)
  const transformErrors = errors => {
    return errors.map(error => {
      if (error.property === '.userInfo.fullName') {
        error.message = 'Full name is required'
      }
      if (error.property === '.userInfo.email') {
        error.message = 'Please enter a valid email'
      }
      if (error.property === '.userInfo.artistID') {
        error.message = 'Please enter a valid Artist ID'
      }
      if (error.property === '.userReason.reason') {
        error.message = 'Please select one of the options'
      }
      if (error.property === '.userReason.orderNumber') {
        error.message = 'Please enter a valid order number'
      }
      if (error.property === '.userSubject') {
        error.message = 'Please provide a subject'
      }
      if (error.property === '.userMessage') {
        error.message = 'Please provide more information'
      }
      if (error.name === 'oneOf' || error.name === 'enum') {
        error.message = undefined
      }
      return error
    })
  }

  const [submitCount, setSubmitCount] = useState(0)

  const handleSubmit = ({ formData }, e) => {
    const submitBtnTexts = document.querySelectorAll('.btn--submit span')
    const submittingText = document.querySelector('.btn--submit .submitting')
    const submittedText = document.querySelector('.btn--submit .submitted')
    submitBtnTexts.forEach(text => text.classList.remove('show'))
    submittingText.classList.add('show')

    console.log('Data submitted: ', formData)
    const {
      userInfo: { email, fullName: name, artistID: cf_customer_number },
      userSubject: subject,
      userReason: { reason: type, orderNumber: cf_order_number },
      userMessage: description,
      ccCustomer: { ccEmail: cc_email },
      attachments,
    } = formData
    const payload = {
      email,
      name,
      subject,
      description,
      type,
      attachments,
      cc_emails: cc_email ? [cc_email] : null,
      custom_fields: {
        cf_customer_number: `${cf_customer_number}`,
        cf_order_number: `${cf_order_number}`,
      },
    }
    axios
      .post(`${GATSBY_FORM_SERVICE_BASE_URL}/support/tickets`, payload)
      .then(data => {
        submittingText.classList.remove('show')
        submittedText.classList.add('show')
        setTimeout(() => {
          setSubmitCount(submitCount => submitCount + 1)
        }, 4000)
      })
      .catch(err => {
        // TODO: display errors
        console.log(err.message || err)
      })
  }

  return (
    <>
      <ContainerMedium>
        <h2 className="headline--large">How can we help you?</h2>
        <p className="headline--intro">
          If you can’t find the answer you’re looking for in our{' '}
          <Link to="/support/">FAQ</Link> or{' '}
          <Link to="/support/dos-and-donts/">Dos & Don'ts</Link>, send us a
          message and someone from our ASK Team will get back to you as soon as
          possible.
        </p>
        <p className="headline--intro">For compliance inquiries, please use the following <a
          href="https://fieldwatch.co/api/v1/clients/311/reported_incidents/new?auth_token=67259b577b4d745ece304f0e01e11443&field_submission_id=1030&token=31c17aafa7188c71d55eee509fa0fa5d"
          rel="noopener noreferrer" target="_blank">link</a>.
        </p>
        <p className="headline--intro">To report a policy violation, please utilize this <a
          href="https://fieldwatch.co/api/v1/clients/311/reported_incidents/new?auth_token=d2b5263b1e3550babd424749f2c108af&field_submission_id=1028&token=31c17aafa7188c71d55eee509fa0fa5d"
          rel="noopener noreferrer" target="_blank">link</a>.
        </p>
        <Form
          key={submitCount}
          noHtml5Validate
          showErrorList={false}
          transformErrors={transformErrors}
          uiSchema={uiSchema}
          schema={schema}
          onChange={handleChange}
          onSubmit={handleSubmit}
          onError={log('errors')}
        >
          <button className="btn--submit" type="submit">
            <span className="unsubmitted show">Send</span>
            <span className="submitting">Sending</span>
            <span className="submitted">
              Sent
              <CheckmarkIcon />
            </span>
          </button>
        </Form>
      </ContainerMedium>
    </>
  )
}

export default Contact
